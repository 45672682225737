<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap18GoModuleProxies"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 18: Go Module Proxies" image-name="proxy.jpg" image-alt="Go Module Proxies"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a module proxy?</p></li>
<li><p>The reasons behind the creation of a module proxy.</p></li>
<li><p>How to configure the go Module proxy.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Proxy Server</p></li>
<li><p>Forward Proxy</p></li>
<li><p>Server / Client</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The go get command uses a <strong>proxy</strong> to fetch the latest releases of modules. In this section, we will detail how this proxy works.</p>
<div id="what-is-a-proxy-server" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is a proxy server? <a href="#what-is-a-proxy-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A server is an application that provides functionality for other programs which are called <strong>clients</strong><a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p>
<ul>
<li>Clients are, for instance, web browsers</li>
</ul></li>
<li><p>When this application provides functionality via the internet, we call it a “Web Server”.</p></li>
<li><p>A Proxy Server is a special kind of server.</p></li>
<li><p>It will act as an intermediary between the clients and one or more servers.</p></li>
</ul>
<p>A proxy server will receive requests for resources those request can for instance :</p>
<ul>
<li><p>handle the request directly</p></li>
<li><p>forward it to the servers behind it (we call this kind of proxy server “Forward proxy”)</p></li>
<li><p>stop the request</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/forward_proxy.png')" alt="Sequence diagram of a forward proxy"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Sequence diagram of a forward proxy</figcaption>
</figure>
<p>Proxy servers have many use cases :</p>
<ul>
<li><p>Filter and monitor internet traffic.</p>
<ul>
<li><p>Companies intensively use forward proxies to keep an eye on employees activity</p></li>
<li><p>They may also block some requests to forbidden websites</p></li>
</ul></li>
<li><p>Caching</p>
<ul>
<li><p>We can use proxies to cache resources.</p></li>
<li><p>Instead of calling the server targeted, they will send a cached response to the client.</p></li>
</ul></li>
</ul>
<div id="the-reasons-behind-the-birth-of-the-go-module-proxy" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> The reasons behind the birth of the Go Module Proxy <a href="#the-reasons-behind-the-birth-of-the-go-module-proxy"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The Go Module Proxy is a recent addition to the language tooling. Before the go get command downloaded, the module <strong>directly</strong> from the code-sharing website. It has some drawbacks :</p>
<ul>
<li><p>A module can be deleted from the site sharing website by the developer.</p></li>
<li><p>A version can also be deleted.</p>
<ul>
<li><p>A tag was deleted...</p></li>
<li><p>A commit can be removed...</p></li>
</ul></li>
</ul>
<p>If your application depends on this module building your Go application might be <strong>impossible</strong>.</p>
<div id="how-does-it-work" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> How does it Work <a href="#how-does-it-work"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The go get command can fetch dependencies from a module proxy server without touching the original server that hosts the code (GitHub, GitLab,...).</p>
<figure>
<b-img :src="require('@/assets/images/go_proxy_found.png')" alt="The Go Command (go get) try to fetch modules from the proxy server"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The Go Command (go get) try to fetch modules from the proxy server</figcaption>
</figure>
<p>When the code is not available on the proxy server, Go can download it directly from the hosting server.</p>
<figure>
<b-img :src="require('@/assets/images/go_proxy_not_available.png')" alt="When the module is not on the proxy"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">When the module is not on the proxy</figcaption>
</figure>
<p>We can change this behavior through an environment variable.</p>
<div id="configuration-of-the-go-module-proxy" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Configuration of the Go Module Proxy <a href="#configuration-of-the-go-module-proxy"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The proxy configuration is set into the environment variable <strong>GOPROXY.</strong></p>
<p>By typing the command</p>
<pre data-float=""><span v-highlightjs><code class="go" v-pre style="display: inline">go env GOPROXY</code></span></pre>
<p>you can check its current value. At the time of writing, the default value is :</p>
<pre v-highlightjs><code class="go" v-pre >https://proxy.golang.org,direct</code></pre>
<ul>
<li><p>The value of <strong>GOPROXY</strong> is a list</p></li>
<li><p>The list is separated by commas “,” or pipes “|”</p></li>
<li><p>It is composed of URLs of Go modules proxy and/or special keywords.</p></li>
<li><p>The keywords available are</p>
<p>“off” : it means turn off the feature</p>
<p>“direct” : it instructs the tool to download it directly from the code hosting server.</p></li>
</ul>
<p>Go will attempt to download new modules from each specified Go Module Proxy URLs from left to right. When it encounters the keyword “<strong>direct</strong>” it will try to download the module directly from the source hosting website.</p>
<figure>
<b-img :src="require('@/assets/images/go_proxy_env_conf.png')" alt="GOPROXY environment configuration"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">GOPROXY environment configuration</figcaption>
</figure>
<div id="disable-go-module-proxy" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> Disable Go Module Proxy <a href="#disable-go-module-proxy"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To disable the feature completely set the <strong>GOPROXY</strong> env to the value “<strong>off</strong>”</p>
<div id="the-four-endpoints-of-a-go-module-proxy-advanced" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> The four endpoints of a Go Module Proxy (advanced) <a href="#the-four-endpoints-of-a-go-module-proxy-advanced"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A go module proxy will expose four endpoints (all GET) <span v-highlightjs><code class="go" v-pre style="display: inline">/&lt;module&gt;/@v/list</code></span> : get the list of versions known by the proxy server.</p>
<ol type="1">
<li><p>Ex : https://proxy.golang.org/ <strong>gitlab.com/loir402/bluesodium</strong>/@v/ <strong>list</strong></p>
<pre v-highlightjs><code class="go" v-pre >v1.0.0
v1.0.1</code></pre></li>
</ol>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">/&lt;module&gt;/@latest</code></span> : get the latest version info formated in JSON</p>
<ol type="1">
<li><p>Ex : https://proxy.golang.org/ <strong>gitlab.com/loir402/bluesodium</strong>/@v/latest</p>
<pre v-highlightjs><code class="go" v-pre >{&quot;Version&quot;:&quot;v1.0.1&quot;,&quot;Time&quot;:&quot;2021-01-20T18:49:34Z&quot;}</code></pre></li>
</ol>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">/&lt;module&gt;/@v/&lt;version&gt;.info</code></span> : get the metadata about the module’s version and the time at which it was committed.</p>
<ol type="1">
<li><p>Ex : https://proxy.golang.org/ <strong>gitlab.com/loir402/bluesodium</strong>/@v/v1.0.1 <strong>.info</strong></p>
<pre v-highlightjs><code class="go" v-pre >{&quot;Version&quot;:&quot;v1.0.1&quot;,&quot;Time&quot;:&quot;2021-01-20T18:49:34Z&quot;}</code></pre></li>
</ol>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">/&lt;module&gt;/@v/&lt;version&gt;.mod</code></span> : return the go.mod file of the given version</p>
<ol type="1">
<li>Ex : https://proxy.golang.org/ <strong>gitlab.com/loir402/bluesodium</strong>/@v/v1.0.1 <strong>.mod</strong></li>
</ol>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">/&lt;module&gt;/@v/&lt;version&gt;.zip</code></span> : return the zipped version of the module.</p>
<ol type="1">
<li>Ex : https://proxy.golang.org/ <strong>gitlab.com/loir402/bluesodium</strong>/@v/v1.0.1 <strong>.zip</strong></li>
</ol>
<div id="common-error-the-newest-version-is-not-downloaded" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Common error: the newest version is not downloaded <a href="#common-error-the-newest-version-is-not-downloaded"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When a newer minor or patch is available for the module, the command</p>
<pre data-float=""><span v-highlightjs><code class="go" v-pre style="display: inline">go get -u modulePath</code></span></pre>
<p>will not always download it immediately. The service has a cache in place to improve performance. Hence to solve this, you have two solutions :</p>
<ul>
<li><p>Wait and try later (the cache will be invalidated)</p></li>
<li><p>Target specifically the version you want to use in the go get command</p>
<pre data-float=""><span v-highlightjs><code class="go" v-pre style="display: inline">go get -u modulePath@v1.0.2</code></span></pre></li>
</ul>
<div id="useful-links-to-debug-issues" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Useful links to debug issues <a href="#useful-links-to-debug-issues"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>How to <strong>get the list of available versions</strong> for a module on a proxy?</p>
<pre v-highlightjs><code class="go" v-pre >https://PROXY_URL/MODULE_PATH/@v/list</code></pre>
<ul>
<li><p>Ex : https://proxy.golang.org/gitlab.com/loir402/bluesodium/@v/list</p>
<ul>
<li>Will output the list of versions available for a given module</li>
</ul></li>
<li><p>For major version 2 the link is :</p></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >&quot;https://proxy.golang.org/gitlab.com/loir402/bluesodium/v2/@v/list&quot;</code></pre>
<ul>
<li><p>How to <strong>download source code</strong> of a module at a specific version</p>
<pre v-highlightjs><code class="go" v-pre >https://PROXY_URL/MODULE_PATH/@v/VERSION.zip</code></pre>
<ul>
<li><p>Ex : https://proxy.golang.org/gitlab.com/loir402/bluesodium/@v/v1.0.1.zip</p>
<ul>
<li>Will trigger the download of the source code</li>
</ul></li>
</ul></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h3 data-number="10.0.1"><span class="header-section-number">10.0.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ol type="1">
<li><p>What is the name of the environment variable that controls the Go Module Proxy usage?</p></li>
<li><p>How to disable the usage of module proxies in a go get command?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the name of the environment variable that controls the Go Module Proxy usage?</p>
<ol type="1">
<li>GOPROXY.</li>
</ol></li>
<li><p>How to disable the usage of module proxies in a go get command?</p>
<pre v-highlightjs><code class="go" v-pre >$ GOPROXY=&quot;off&quot; go get gitlab.com/loir402/foo</code></pre></li>
</ol>
<h1 class="unnumbered" id="key-takeaways">Key takeaways</h1>
<ul>
<li><p>A Go module proxy is a proxy server that will store Go modules’ code.</p></li>
<li><p>Instead of downloading the source code directly from the code hosting server (ex: GitHub), the go command <strong>can</strong> download it from the proxy server.</p></li>
<li><p>Some modules might be unknown to the proxy; in that case, the server will generally add them afterward.</p>
<ul>
<li><p>For instance, the official Go Module Proxy has that system in place</p></li>
<li><p>Other Go Module Proxies might implement different strategies</p></li>
</ul></li>
<li><p>There is an official Go Proxy available to all, which is https://proxy.golang.org/</p></li>
<li><p>You can also deploy your own Go Module Proxy Server / or use a hosted alternative</p></li>
<li><p>To use a specific proxy, you will have to modify the GOPROXY variable.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Definition from Wikipedia, see https://en.wikipedia.org/wiki/Server_(computing)<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
<BuyCopyInvite></BuyCopyInvite>
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap18GoModuleProxies"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap17GoModules'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Go modules</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap19UnitTests'}">
									<p><u><small>Next</small></u></p>
									<p><small>Unit Tests</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Go Module Proxies - Practical Go Lessons"
const description = "The go get command uses a proxy to fetch the latest releases of modules. In this section, we will detail how this proxy works."

export default {
  name: "Chap18GoModuleProxies",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
